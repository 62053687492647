@import '@appkit4/styles/themes/appkit.orange.min.css';
@import '@appkit4/styles/scss/variables';
@import '@appkit4/react-components/dist/styles/appkit4-react.min.css';

$colors: (
  'red': #D04A02,
  'blue': #4299e1,
  'green': #48bb78,
);

$spacing-scale: (
  '0': 0,
  '1': 0.25rem,
  '2': 0.5rem,
  '4': 1rem,
  '8': 2rem,
);

$text-sizes: (
  'xs': 0.75rem,
  'sm': 0.875rem,
  'base': 1rem,
  'lg': 1.125rem,
  'xl': 1.25rem,
);

* {
  vertical-align: middle;
}

body {
 background-color: #F7F7F7 !important;
}

img {
 width: 100%;
}

h2 {
 font-size: 24px;
 line-height: 32px;
 font-weight: 500;
}

button.ap-button.ap-button-text.ap-button-icon.tooltip-width {
  width: 50px;
}

.err {
  color: $color-text-error;
  font-size: smaller;
}

.ap-container {
 max-width: 100%;
 height: auto;
}

.ap-container:not(.g-0):not(.gx-0) {
 --ap-gutter-x: 1rem;
}

.padding {
 padding: 10px;
}

.row {
  padding: 5px;
}

.home-panel .ap-panel-body {
 padding: 0;
}

.banner {
 width: 80% !important;
 float: right;
}

.page-loader {
 position: fixed;
 top: 50%;
 left: 50%;
 transform: translate(-50%, -50%);
 padding-top: 30px;
}

.back-button {
 padding-left: 0;
}

.footer-padding {
 padding-top: 100px;
}

.title {
 text-align: left;
 color: white;
 font-weight: normal;
 margin-top: 15%;
 font-size: 42px;
 vertical-align: top;
}

.title2 {
  text-align: left;
  color:  map-get($colors, 'red');
  font-weight: normal;
  margin-top: 0%;
  margin-left: 1%;
  margin-bottom: 1%;
  font-size: 42px;
 }

.title3 {
  text-align: left;
  color:  map-get($colors, 'red');
  font-weight: normal;
  margin-top: 2%;
  margin-left: 1%;
  margin-bottom: 2%;
  font-size: 20px;
 }

.banner-background { 
 background-color:  map-get($colors, 'red'); 
}

.banner-image {
 padding-left: 0 !important;
 padding-right: 0 !important;
 background-color:  map-get($colors, 'red'); 

}

.container, .container-md, .container-sm {
 max-width: 100% !important;
}

h3 {
 color:  map-get($colors, 'red');
 margin-top: 20px !important;
 margin-bottom: 10px !important;
}

h4 {
  font-size: 20px;
  line-height: 32px;
  font-weight: 500;
 }

.red-text {
  color: map-get($colors, 'red') !important;
}

.green-text {
  color: map-get($colors, 'green');
}

.indent1 {
 margin-left: 20px;
}

.indent2 {
 margin-left: 40px;
}

.indent3 {
 margin-left: 60px;
}

.indent4 {
 margin-left: 80px;
}

.ap-tabset-container .ap-tabset-toggle-wrapper.underline {
 margin-bottom: 20px;
}

.ap-footer-content {
 text-align: center;
}

.right {
 float: right;
}

.inputNumberArrow {
 display: none;
}

.ap-footer-content {
 text-align: left;
 margin-left: 10px;
}

p {
 margin-bottom: 20px;
}

.ap-tabset-content {
 margin-top: 20px;
}

.survey-table {
 width: 100%;
}

.test, .test2 {
  background-color:  map-get($colors, 'red'); 
 }

 .table-container {
  display: flex;
  justify-content: space-around; /* or space-between, center, etc. */
}

.table-container > table {
  flex: 1; /* This makes each table take equal space */
  margin: 10px; /* Optional for spacing */
}

.switch_text{
  display: flex;
  align-items: center;
}

.icon-up-chevron-outline,
.icon-down-chevron-outline {
  display:none;
}

.two-line-ellipsis {
  // display: -webkit-box;
  // -webkit-box-orient: vertical;
  // -webkit-line-clamp: 2; /* Limit to 2 lines */
  overflow: hidden;
  text-overflow: ellipsis;
  line-clamp: 2; /* Fallback for non-WebKit browsers */
  max-height: 1em; /* Adjust based on line height to ensure two lines are displayed */
  line-height: 1.5em; /* Adjust based on your font size */
}

.align-middle {
  align-items: center;
  justify-content: center;
}

.items-center {
  align-items: center;
}

.justify-center {
  justify-content: center
}

.justify-start {
  justify-content: start
}

@mixin text-color($color) {
  color: map-get($colors, $color);
}

@mixin bg-($color) {
  background-color: map-get($colors, $color);
}

@mixin padding($size) {
  padding: map-get($spacing-scale, $size);
}

@mixin margin($size) {
  margin: map-get($spacing-scale, $size);
}

@mixin text-size($size) {
  font-size: map-get($text-sizes, $size);
}

@mixin pr($size) { padding-right: map-get($spacing-scale, $size); }
@mixin pl($size) { padding-left: map-get($spacing-scale, $size); }
@mixin pb($size) { padding-bottom: map-get($spacing-scale, $size); }
@mixin pt($size) { padding-top: map-get($spacing-scale, $size); }

@mixin px($size) {
  padding-left: map-get($spacing-scale, $size);
  padding-right: map-get($spacing-scale, $size);
}
@mixin py($size) {
  padding-top: map-get($spacing-scale, $size);
  padding-bottom: map-get($spacing-scale, $size);
}

@mixin mt($size) { margin-top: map-get($spacing-scale, $size); }
@mixin mr($size) { margin-right: map-get($spacing-scale, $size); }
@mixin mb($size) { margin-bottom: map-get($spacing-scale, $size); }
@mixin ml($size) { margin-left: map-get($spacing-scale, $size); }
@mixin mx($size) {
  margin-left: map-get($spacing-scale, $size);
  margin-right: map-get($spacing-scale, $size);
}
@mixin my($size) {
  margin-top: map-get($spacing-scale, $size);
  margin-bottom: map-get($spacing-scale, $size);
}
.group-data-modal-top-input-header {
  padding-top:"0";
  padding-bottom:"13px";

  p {
    padding-inline-start:"5px";
    padding-top:"0"
  }
}
.space-between {
  display:flex;
  justify-content:space-between;
}

.notice-content-width {
  width: calc(100vw - 110px) !important
}
