.th-flex-container {
    display: flex;
}

.th-vertical-align-top {
    vertical-align: top;
}

.text-align-left {
    text-align: left;
}

.icon-btn {
    display: flex;
    justify-content: center;
    padding: 0 !important;
    height: 25px;
}