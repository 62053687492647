.flex-space-between {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.flex-end {
    display: flex;
    align-items: center;
    justify-content: end;
    gap: 2rem;
}

.flex-center {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 2rem;
    margin: 0.7rem;
}

.custom-scrollable {
    max-height: 30vh;
    overflow: scroll;
}

.custom-padding-top {
    padding-top: 1rem;
}
