.icon-btn {
    display: inline-block !important;
    padding: 0px !important;
    height: 25px;
    width: 25px;
}

.Appkit4-icon, .Appkit4-icon::before {
    padding-bottom: 1.5px;
}

.big-tooltip {
    max-width: 50rem; 
    padding: 0 5px;
}
