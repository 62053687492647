.no-margin p {
    margin: 0;
}

.flex-container-modal {
    display: flex;
    gap: 10px;
    align-items: center;
}

.flex-grow {
    flex: 1;
}
