.table-head {
    text-align: left;
    vertical-align: top;
}

.tooltip-btn {
    width: 20px;
}

.flex-align {
    display: flex;
    align-items: center;
}